<template>
   <div class="content">
     <div class="row">

 <div class="col-md-7">
        
          <TableProductByStation></TableProductByStation>
          
        
 </div>
            <div class="col-md-5">
              <NewProductByStation></NewProductByStation>

        </div>
     </div>
   </div>
</template>

<script>
import Vue from 'vue'
import TableProductByStation from '../tables/TableProductByStation/TableProductByStation.vue'
import NewProductByStation from './NewProductByStation'
import { SpringSpinner } from 'epic-spinners'
import {
    mapState,
    mapActions
} from "vuex";
export default {
  name: 'Table',
  components: {
    SpringSpinner,
    TableProductByStation,
    NewProductByStation
  },
  data () {
    return {
      namestation:null
    }
  },
    mounted() {
        var id = this.$route.params.idstation;
        if (id !== null) {
            this.loadStation(id)
                .then(data => {
                    this.namestation = data.name;                   
                })
                .catch(data => {
                    this.addToastMessage({
                        text: "Ocurrio un error",
                        type: "success"
                    });
                });
        } else {
            this.addToastMessage({
                text: "Ocurrio un error",
                type: "success"
            });
        }
    },        
  methods: {
      ...mapActions([           
            "loadStation",
            "addToastMessage"
        ]),
    back () {
       this.$router.go(-1);
     // this.$router.push('/')
    },
    newProductStation () {
      this.$router.push('product/new')
    }

  }
}
</script>

<style lang="scss">

  .color-icon-label-table {
    td:first-child {
      width: 1rem;
    }
  }
</style>
