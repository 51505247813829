export default {
  tableFields: [
   /* {
      name: '__component:badge-column',
      title: '',
      dataClass: 'text-center',
      width: '4%'
    },*/
    {
      name: 'product.name',
      title: 'Producto',
      sortField: 'name',
      width: '50%'
    },
    {
      name: 'vat',
      title: 'VAT',
      sortField: 'vat',
      width: '20%'
    },
    {
      name: 'price',
      title: 'Precio',
      sortField: 'price',
      width: '20%'
    },
    {
      name: 'order',
      title: 'Orden',
      sortField: 'order',
      width: '20%'
    },
    {
      name: '__slot:active',
      title: 'Estatus',
      titleClass: 'center aligned',
      dataClass: 'center aligned',
      width: '24%',
    },
  ],
  sortFunctions: {
    'product.name': function (item1, item2) {
      return item1 >= item2 ? 1 : -1
    },
  }
}
